






















import Vue from 'vue';

export default Vue.extend({
  name: "MallMain2d",
  data: () => {
    return {
      mallStoreData: [],
      mallStore: "",
    };
  },
  methods: {
    changeStore(): void {
      if(this.mallStore?.length) {
        this.$router.push({ path: `/place/${this.mallStore}`});
        this.mallStore = "";
      }
    },
    async getStores(){
      const stores = await this.$http.get(`/mall/stores`);
      stores.data.stores.forEach(store => {
        this.mallStoreData.push({
          title: store.name,
          slug: store.slug
        })
      })
    }
  },
  mounted() {
    this.getStores();
  }
});
